import { useContext } from "react";

import authStorage from "../auth/storage";
import AppContext from "../context/context";

const useAuth = () => {
  const { user, setUser } = useContext(AppContext);

  const login = async (user) => {
    await authStorage.storeToken(user);
    setUser(user);
  };

  const logout = async () => {
    setUser(null);
    authStorage.removeToken();
  };

  return { user, login, logout };
};

export default useAuth;
