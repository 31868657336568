import { Keyboard } from "react-native";

import logger from "./logger";

const Submit = async (api, payload, onError, onSuccess, afterSuccess, afterError, hasDone) => {
  try {
    Keyboard.dismiss();
    const response = api ? await api.request(payload) : { ok: false };
    if (!response.ok) {
      onError && onError(response.data?.message || "Something went wrong!");
      afterError && afterError(response);
      return false;
    }
    onSuccess && onSuccess(true);
    if (hasDone)
      setTimeout(() => {
        onError && onError(null);
        onSuccess && onSuccess(false);
        afterSuccess && afterSuccess(response.data);
        return true;
      }, 1500);
    else {
      afterSuccess && afterSuccess(response.data);
      return true;
    }
  } catch (e) {
    logger.log(e);
    afterError && afterError(e);
  }
  return false;
};
export default Submit;
