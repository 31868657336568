import { useFormikContext } from "formik";

import AppButton from "../AppButton";

const SubmitButton = ({ title, type = "save", ...otherProps }) => {
  const { handleSubmit, isValid, dirty } = useFormikContext();
  if (type === "save") {
    return (
      <AppButton
        {...otherProps}
        title={title}
        type={type}
        onPress={handleSubmit}
        disabled={!(isValid && dirty)}
      />
    );
  } else if (type === "done") {
    return <AppButton {...otherProps} title={title} type={type} onPress={handleSubmit} />;
  }
};

export default SubmitButton;
