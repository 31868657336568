import { Platform, StyleSheet } from "react-native";

import themeColors from "./themeColors";
const styles = StyleSheet.create({
  viewFlex: {
    flex: 1,
    backgroundColor: themeColors.colors.white,
  },
  container: {
    flex: 1,
    padding: 10,
    borderRadius: 0,
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonStyle: {
    backgroundColor: themeColors.colors.mainColor,
    borderRadius: 8,
    paddingVertical: 13,
    width: "100%",
  },
  titleStyle: {
    fontSize: 17,
    fontWeight: "700",
    color: themeColors.colors.white,
  },
  headerArrow: {
    margin: Platform.OS === "android" ? 2 : 10,
  },
  headerContainer: {
    padding: 5,
    borderRadius: 0,
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    flexDirection: "row",
    alignContent: "center",
  },
  backTextWhite: {
    color: themeColors.colors.background,
  },
  innerContainer: {
    alignItems: "flex-start",
    flexDirection: "column",
  },
  errorContainer: {
    alignItems: "flex-start",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  itemHeading: {
    fontWeight: "bold",
    fontSize: 18,
    color: themeColors.colors.black,
  },
  itemSubHeading: {
    fontSize: 16,
    marginTop: 5,
    color: themeColors.colors.black,
  },
  formContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 10,
    marginLeft: 10,
    marginTop: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  button: {
    height: 45,
    padding: 10,
    borderRadius: 8,
    backgroundColor: themeColors.colors.primary,
    color: themeColors.colors.white,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 100,
  },
  buttonText: {
    color: themeColors.colors.white,
    fontSize: 20,
  },
  icon: {
    fontSize: 30,
  },
  row: {
    alignItems: "flex-start",
    padding: 10,
    backgroundColor: themeColors.colors.grey4,
    justifyContent: "space-between",
    height: 80,
    borderRadius: 10,
    flexDirection: "row",
    marginBottom: 10,
  },
  rowControls: {
    alignItems: "center",
    backgroundColor: "transparent",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textfield: {
    marginBottom: 10,
    padding: 10,
    fontSize: 15,
    color: "#000000",
    backgroundColor: "#e0e0e0",
    borderRadius: 5,
  },
  buttonUpdate: {
    marginTop: 25,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 7,
    elevation: 10,
    backgroundColor: "#0de065",
  },
  inputContainer: {
    backgroundColor: themeColors.colors.lightGrey,
    color: themeColors.colors.white,
    borderRadius: 10,
    flexDirection: "row",
    padding: 10,
    // marginVertical: 5,
    // borderBottomColor: themeColors.colors.grey4,
    // borderBottomWidth: 1,
  },
  inputContainerFocused: {
    // borderBottomColor: themeColors.colors.black,
  },
  text: {
    fontSize: 16,
    fontWeight: "700",
    fontFamily: Platform.OS === "android" ? "Roboto" : "Avenir",
    color: themeColors.colors.black,
    width: "100%",
  },
  textInput: {
    fontFamily: Platform.OS === "android" ? "Roboto" : "Avenir",
    color: themeColors.colors.black,
    width: "100%",
    fontSize: 14,
  },
  textIcon: {
    marginRight: 10,
  },
  inputLabel: {
    position: "absolute",
    fontSize: 12,
    left: 40,
  },
});
export default styles;
