// import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";

const key = "auth_token";

const storeToken = async (token) => {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(token));
    // await SecureStore.setItemAsync(key, JSON.stringify(token));
  } catch (error) {
    console.error("storeToken", error);
  }
};

const getToken = async () => {
  try {
    const user = await getUser();
    return user ? user.jwt : null;
  } catch (error) {
    console.error("getToken", error);
  }
};

const getUser = async () => {
  try {
    const user = await AsyncStorage.getItem(key);

    // const user = await SecureStore.getItemAsync(key);
    return JSON.parse(user);
  } catch (error) {
    console.error("getUser", error);
  }
  return null;
};

const removeToken = async () => {
  try {
    await AsyncStorage.removeItem(key);
    // await SecureStore.deleteItemAsync(key);
  } catch (error) {
    console.error("removeToken", error);
  }
};

export default { getUser, getToken, removeToken, storeToken };
