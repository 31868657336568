const routes = Object.freeze({
  LOGIN: "Login",
  REGISTER: "Register",
  FORGOT: "Forgot",
  PASSWORD: "CreatePassword",
  CONGRATS: "Congrats",
  UPLOAD_IMAGE: "UploadImg",
  RESET: "ResetPassword",
  HOME: "Home",
  MAINHOME: "MainHome",
  CONTACTS: "Contacts",
  CONNECTNOW: "Connect Now",
  MAINCONNECTNOW: "Main Connect Now",
  USERPROFILE: "userProfile",
  REPLIES: "replies",
  MYTRIP: "MyTrip",
  MYMEETING: "MyMeeting",
  PERSONALINFO: "PersonalInformation",
  PREFERENCES: "Preferences",
  TAGMANAGER: "TagManager",
  CHANGEPASSWORD: "ChangePassword",

  VIDEO: "Video",
  MAIN_VIDEO: "MainVideo",
  SEARCH: "Search",
  MAIN_SEARCH: "MainSearch",
  SEARCHITEM: "SearchItem",
  NOTES: "Notes",
  NOTIFICATION: "Notifications",
});

export default routes;
