import { memo } from "react";
import { Image, StyleSheet } from "react-native";

const NoData = () => {
  return <Image source={require("../../assets/noData.png")} style={styles.imgStyles} />;
};
const styles = StyleSheet.create({
  imgStyles: {
    width: 220,
    height: 150,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: [{ translateX: -100 }, { translateY: 100 }],
  },
});
export default memo(NoData);
