import LottieView from "lottie-react-native";
import { StyleSheet, View } from "react-native";

import theme from "../styles/theme";

const AppActivityIndicator = ({
  visible = false,
  source = require("../../assets/animations/loader.json"),
  width = 100,
  height = 100,
}) => {
  if (!visible) return null;
  return (
    <View style={styles.overlay}>
      {/* <LottieView resizeMode="cover" autoPlay loop source={source} style={{ width, height }} /> */}
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    alignItems: "center",
    backgroundColor: theme.colors.white,
    height: "100%",
    justifyContent: "center",
    opacity: 0.6,
    position: "absolute",
    width: "100%",
    zIndex: 1,
  },
});

export default AppActivityIndicator;
