import client from "./client";
import { notificationAPI } from "./endpoints";

const getNotifications = (params) => client.get(notificationAPI, { ...params });

const updateNotification = (params) => client.put(`${notificationAPI}/${params.id}`, params.values);

const postNotification = (params) => client.post(notificationAPI, { ...params });

export default { getNotifications, updateNotification, postNotification };
