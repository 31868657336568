import { AppForm, AppFormField, SubmitButton } from "components/forms";
import ErrorMessage from "components/forms/ErrorMessage";
import { useState } from "react";
import { Text, View, StyleSheet } from "react-native";
import theme from "styles/theme";
import { loginValidationSchema } from "validation";

import AuthWrapper from "./AuthWrapper";
import authAPI from "../../api/auth";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import Submit from "../../utils/handleSubmit";

const { colors } = theme;

const LoginScreen = ({ navigation, route }) => {
  const userInfo = route.params;
  const [loginError, setLoginError] = useState(null);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const loginApi = useApi(authAPI.login);
  const auth = useAuth();

  const handleSubmit = async (values) => {
    Submit(loginApi, values, setLoginError, setLoginSuccess, auth.login, null, true);
  };

  return (
    <AuthWrapper loading={loginApi.loading} done={loginSuccess}>
      {userInfo?.user?.id && <Text style={styles.loginText}>Please wait till admin confirm </Text>}
      <AppForm
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={loginValidationSchema}
      >
        <Text style={styles.label}>Username</Text>
        <View style={{ marginBottom: 16 }}>
          <AppFormField
            placeholder="Enter your username"
            name="username"
            autoCapitalize="none"
            containerStyle={styles.inputContainer}
          />
        </View>
        <Text style={styles.label}>Password</Text>
        <AppFormField
          placeholder="Enter your password "
          name="password"
          secureTextEntry
          containerStyle={styles.inputContainer}
        />
        <ErrorMessage error={loginError} visible={loginError} />

        <SubmitButton title="Sign In" buttonStyle={styles.loginButton} />
        {/* <View style={styles.signUpWrapper}>
          <Text style={styles.signUpTextSimple}>Don’t have an account?</Text>
          <TouchableOpacity onPress={() => navigation.navigate(routes.REGISTER)}>
            <Text style={styles.signUpText}> Create an account</Text>
          </TouchableOpacity>
        </View> */}
      </AppForm>
    </AuthWrapper>
  );
};

const styles = StyleSheet.create({
  loginButton: {
    backgroundColor: colors.mainColor,
    borderRadius: 8,
    paddingVertical: 13,
    marginTop: 30,
  },
  label: {
    color: colors.mainColor,
    fontWeight: "700",
    fontSize: 12,
    marginBottom: 8,
  },
  inputContainer: {
    backgroundColor: colors.white,
    color: colors.white,
    borderRadius: 10,
    flexDirection: "row",
    padding: 10,
    borderWidth: 1,
    borderColor: colors.disabledGrey,
  },
  signUpWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 16,
  },
  signUpTextSimple: {
    fontSize: 10,
  },
  signUpText: {
    fontSize: 10,
    fontWeight: "700",
    color: colors.mainColor,
  },
  loginText: { textAlign: "left", fontSize: 18, marginBottom: 16, color: colors.mainColor },
  installWrapper: { height: 200, flex: 1 },
});

export default LoginScreen;
