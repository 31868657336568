import { useState } from "react";
import { View, StyleSheet, ScrollView, Alert } from "react-native";

import NotesItem from "../../components/NotesItem";
import AppTextInput from "../../components/AppTextInput";
import theme from "../../styles/theme";
import useApi from "../../hooks/useApi";
import notesAPI from "../../api/notes";
import Submit from "../../utils/handleSubmit";
import AppScreen from "../../components/AppScreen";

const { colors } = theme;

const NotesScreen = ({ navigation, route }) => {
  const { notes, userId } = route.params;

  const saveNotesApi = useApi(notesAPI.saveNotes);

  const [msg, setMsg] = useState(null);

  const saveNote = async () => {
    Submit(
      saveNotesApi,
      { id: userId, message: msg },
      () => {
        Alert.alert("Error", "There was an error saving the new note. Please try again later.");
      },
      () => {
        Alert.alert("Success", "Your note has been saved successfully!");
        navigation.goBack();
      }
    );
  };

  return (
    <AppScreen keyboardAware={true}>
      <ScrollView
        style={styles.mainWrapper}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollViewContent}
      >
        {notes?.map((el) => (
          <NotesItem key={el.id} data={el} />
        ))}
      </ScrollView>
      <View style={styles.messageWrapper}>
        <AppTextInput
          placeholder="Type your message"
          placeholderTextColor={colors.grey}
          rightComponentStyle={styles.rightComponentStyle}
          onChangeText={(e) => setMsg(e)}
          handleIconPress={saveNote}
          isNote={true}
        />
      </View>
    </AppScreen>
  );
};
const styles = StyleSheet.create({
  mainWrapper: {
    margin: 16,
    flex: 1,
  },
  messageWrapper: {
    marginHorizontal: 16,
    paddingHorizontal: 16,
    alignItems: "center",
  },
  scrollViewContent: {
    paddingBottom: 100,
  },
  scrollEnd: {
    height: 70,
  },
  rightComponentStyle: {
    backgroundColor: colors.secondaryColor,
    borderRadius: 7,
    width: 40,
    height: 40,
    justifyContent: "center",
    paddingLeft: 7,
  },
});
export default NotesScreen;
