import {
  AntDesign,
  Entypo,
  EvilIcons,
  FontAwesome,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Foundation,
} from "@expo/vector-icons";
import theme from "styles/theme";

export const renderIcon = (icon, iconType, color, size = 20, style) => {
  if (!icon) return null;
  const type = iconType || "Ionicons";
  switch (type) {
    case "Ionicons":
      return (
        <Ionicons
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
    case "FontAwesome5":
      return (
        <FontAwesome5
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
    case "FontAwesome":
      return (
        <FontAwesome
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
    case "MaterialCommunityIcons":
      return (
        <MaterialCommunityIcons
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
    case "MaterialIcons":
      return (
        <MaterialIcons
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
    case "AntDesign":
      return (
        <AntDesign
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
    case "Entypo":
      return (
        <Entypo
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
    case "EvilIcons":
      return (
        <EvilIcons
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
    case "Foundation":
      return (
        <Foundation
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
    case "Feather":
      return (
        <Feather
          name={icon}
          size={size}
          color={color || theme.colors.inputText}
          style={style || theme.styles.textIcon}
        />
      );
  }
};
