import { Image, Platform, Dimensions, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useRef, useState } from "react";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";

import authStorage from "./src/auth/storage";
import AppContext from "./src/context/context";
import AppNavigator from "./src/navigation/AppNavigator";
import AuthNavigator from "./src/navigation/AuthNavigator";
import routes from "./src/navigation/routes";
import theme from "./src/styles/theme";
import logger from "./src/utils/logger";

logger.start();
// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

async function registerForPushNotificationsAsync() {
  let token;
  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Add this app to your home screen for a better experience!!");
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    // alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }
  return token;
}

export default function App() {
  const [user, setUser] = useState(null);
  const [installedHome, setInstalledHome] = useState(true);
  const [resetCode, setResetCode] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const { width, height } = Dimensions.get("window");

  const checkAuthStatus = async () => {
    const user = await authStorage.getUser();
    setUser(user);
  };

  // const [notification, setNotification] = useState(false);
  // const notificationListener = useRef();
  // const responseListener = useRef();

  const showAddToHomeScreen = () => {
    if (
      navigator.userAgent.toLowerCase().includes("mobile") &&
      !window.matchMedia("(display-mode: standalone)").matches
    ) {
      // Show a prompt or a message to encourage the user to add the app to their home screen
      setInstalledHome(false);
    }
  };

  // useEffect(() => {
  // alert(`usr: ${user?.id}`);
  // if (!user?.id) return;

  // const token = window?.playerId;
  // console.log(token, "sended token");
  // token &&
  //   userApi.request({
  //     id: user?.id,
  //     values: {
  //       crmInfo: user,
  //       playerId: token,
  //     },
  //   });
  // registerForPushNotificationsAsync()
  //   .then((token) => {
  //     //  alert("your token", token);
  //     userApi.request({
  //       id: user?.id,
  //       values: {
  //         crmInfo: user,
  //         playerId: token,
  //       },
  //     });
  //   })
  //   .catch((e) => {});

  // notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
  //   setNotification(notification);
  // });

  // responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
  //   console.log(response);
  // });

  // return () => {
  //   Notifications.removeNotificationSubscription(notificationListener.current);
  //   Notifications.removeNotificationSubscription(responseListener.current);
  // };
  // }, [user?.id, window?.playerId]);

  useEffect(() => {
    async function prepare() {
      try {
        !user && (await checkAuthStatus());
        showAddToHomeScreen();
      } catch (e) {
        logger.log(e);
      } finally {
        await SplashScreen.hideAsync();
      }
    }
    prepare();
    setIsReady(true);
  }, [user]);

  if (!isReady) return null;
  if (!installedHome && Platform.OS === "web") {
    return (
      <>
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Image
            source={require("./assets/install.png")}
            style={{ width: width, height: height }}
            resizeMode="contain"
          />
        </View>
      </>
    );
  }
  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        resetCode,
        setResetCode,
      }}
    >
      <NavigationContainer theme={theme}>
        {user ? <AppNavigator /> : <AuthNavigator initialScreen={routes.LOGIN} />}
      </NavigationContainer>
    </AppContext.Provider>
  );
}
