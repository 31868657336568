import * as Yup from "yup";

const passwordComplexityError =
  "Password must contain at least 8 characters, one uppercase, one number and one special case character";
const passwordAndConfirmationNotMatch = "The password confirmation doesn't match";
const passwordRegEx =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export const loginValidationSchema = Yup.object().shape({
  username: Yup.string().required().label("Username"),
  password: Yup.string().required().label("Password"),
});

export const signUpFirstStepValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First name"),
  lastName: Yup.string().required().label("Last name"),
  code: Yup.number().required("Required!"),
  mobileNumber: Yup.number().required("Required!"),
  baseLocation: Yup.string().required().label("Base location"),
});

export const signUpValidationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  username: Yup.string().required().label("username"),
  password: Yup.string()
    .required()
    .label("Password")
    .matches(passwordRegEx, passwordComplexityError),
  passwordConfirmation: Yup.string()
    .required()
    .label("Password confirmation")
    .oneOf([Yup.ref("password"), null], passwordAndConfirmationNotMatch),
});

export const forgotValidationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});
export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .label("Password")
    .matches(passwordRegEx, passwordComplexityError),
  passwordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref("password")], passwordAndConfirmationNotMatch),
});

export const catchMeValidationSchema = Yup.object().shape({
  location: Yup.string().required().label("Location"),
  time: Yup.string(),
});

export const addMeetingValidationSchema = Yup.object().shape({
  location: Yup.string().required(),
  timeStart: Yup.string().required(),
  dateStart: Yup.string().required(),
});

export const changePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required()
    .label("Old password")
    .matches(passwordRegEx, passwordComplexityError),
  newPassword: Yup.string()
    .required()
    .label("New Password")
    .matches(passwordRegEx, passwordComplexityError),
  confirmNewPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("newPassword")], passwordAndConfirmationNotMatch)
    .label("Confirm password"),
});

export const addEditMeetingValidationSchema = Yup.object().shape({
  location: Yup.string().required("Required!"),
  startDate: Yup.string().required("Required!"),
  startTime: Yup.string().required("Required!"),
});

export const addEditTripValidationSchema = Yup.object().shape({
  location: Yup.string().required("Required!"),
  startDate: Yup.string().required("Required!"),
  startTime: Yup.string().required("Required!"),
  endDate: Yup.string().required("Required!"),
  endTime: Yup.string().required("Required!"),
});
