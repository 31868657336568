import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Platform, StyleSheet } from "react-native";

import AppScreen from "../components/AppScreen";
import VideoScreenNavigator from "./VideoScreenNavigator";
import SearchScreenNavigator from "./SearchScreenNavigator";
import HomeScreenNavigator from "./HomeScreenNavigator";
import SearchSVG from "../svg/SearchSVG";
import HomeSVG from "../svg/HomeSVG";
import VideoSVG from "../svg/VideoSVG";
import routes from "./routes";
import theme from "../styles/theme";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";

const Tab = createBottomTabNavigator();
const AppNavigator = () => (
  <AppScreen>
    <Tab.Navigator
      screenOptions={{
        tabBarLabelStyle: styles.homeTabItemContainer,
        tabBarActiveTintColor: theme.colors.mainColor,
        tabBarInactiveTintColor: theme.colors.inactiveTabBarColor,
      }}
    >
      <Tab.Screen
        name={routes.HOME}
        options={({ route }) => ({
          headerShown: false,
          tabBarIcon: ({ color }) => <HomeSVG name="Home" size={24} color={color} />,
          tabBarStyle: ((route) => {
            const routeName = getFocusedRouteNameFromRoute(route) ?? "";
            if (routeName === routes.NOTIFICATION) {
              return { display: "none" };
            }
            return;
          })(route),
        })}
        component={HomeScreenNavigator}
      />

      <Tab.Screen
        name={routes.VIDEO}
        options={({ route }) => ({
          headerShown: false,
          tabBarIcon: ({ color }) => <VideoSVG name="Video" size={24} color={color} />,
          tabBarStyle: ((route) => {
            const routeName = getFocusedRouteNameFromRoute(route) ?? "";
            if (routeName === "SearchItem") {
              return { display: "none" };
            }
            return;
          })(route),
        })}
        component={VideoScreenNavigator}
      />

      <Tab.Screen
        name={routes.SEARCH}
        options={({ route }) => ({
          headerShown: false,
          tabBarIcon: ({ color }) => <SearchSVG name="Search" size={24} color={color} />,
          tabBarStyle: ((route) => {
            const routeName = getFocusedRouteNameFromRoute(route) ?? "";
            if (routeName === "SearchItem") {
              return { display: "none" };
            }
            return;
          })(route),
        })}
        component={SearchScreenNavigator}
      />
    </Tab.Navigator>
  </AppScreen>
);
const styles = StyleSheet.create({
  homeTabItemContainer: {
    fontSize: 11,
    fontWeight: "400",
    fontFamily: Platform.OS === "android" ? "Roboto" : "Avenir",
  },
});
export default AppNavigator;
