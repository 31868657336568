import { Text, StyleSheet, ScrollView } from "react-native";
import theme from "../../styles/theme";
import Videos from "./Videos";

const { colors } = theme;

const MainVideo = ({ navigation }) => {
  return (
    <ScrollView style={styles.mainWrapper}>
      <Text style={styles.text}>Videos</Text>
      <Videos navigation={navigation} />
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  mainWrapper: {
    margin: 16,
  },
  text: {
    fontSize: 20,
    fontWeight: "700",
    color: colors.mainColor,
    marginBottom: 14,
  },
});
export default MainVideo;
