import { Button } from "@rneui/base";

import theme from "../styles/theme";
const AppButton = ({ children, color = "primary", type = "save", textStyle, ...otherProps }) => {
  if (type === "save") {
    return (
      <Button radius={10} color={theme.colors[color]} textStyle={textStyle} {...otherProps}>
        {children}
      </Button>
    );
  } else if (type === "done") {
    return (
      <Button radius={10} textStyle={textStyle} {...otherProps}>
        {children}
      </Button>
    );
  }
};

export default AppButton;
