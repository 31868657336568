import { AppForm, AppFormField, SubmitButton } from "components/forms";
import ErrorMessage from "components/forms/ErrorMessage";
import { useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import theme from "styles/theme";
import { signUpValidationSchema } from "validation";

import AuthWrapper from "./AuthWrapper";
import authAPI from "../../api/auth";
import useApi from "../../hooks/useApi";
import Submit from "../../utils/handleSubmit";
import routes from "../../navigation/routes";

const { colors } = theme;

const SignupScreen = ({ navigation }) => {
  const [registerError, setRegisterError] = useState(null);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const registerApi = useApi(authAPI.register);

  const handleSubmit = async (values) => {
    Submit(
      registerApi,
      {
        ...values,
      },
      setRegisterError,
      setRegistrationSuccess,
      (response) => {
        navigation.navigate(routes.LOGIN, response);
      },
      null,
      true
    );
  };

  return (
    <AuthWrapper loading={registerApi.loading} done={registrationSuccess}>
      <AppForm
        initialValues={{
          email: "",
          username: "",
          password: "",
          passwordConfirmation: "",
          confirmed: true,
        }}
        onSubmit={handleSubmit}
        validationSchema={signUpValidationSchema}
      >
        <Text style={styles.label}>Email</Text>
        <View style={{ marginBottom: 16 }}>
          <AppFormField
            placeholder="Email"
            name="email"
            autoCapitalize="none"
            containerStyle={styles.inputContainer}
          />
        </View>
        <Text style={styles.label}>Username</Text>
        <View style={{ marginBottom: 16 }}>
          <AppFormField
            placeholder="Enter your username"
            name="username"
            autoCapitalize="none"
            containerStyle={styles.inputContainer}
          />
        </View>
        <View style={{ marginBottom: 16 }}>
          <Text style={styles.label}>Password</Text>
          <AppFormField
            placeholder="Enter your password "
            name="password"
            secureTextEntry
            containerStyle={styles.inputContainer}
          />
          <Text style={styles.label}>Confirm password</Text>
          <AppFormField
            placeholder="Confirm your password "
            name="passwordConfirmation"
            secureTextEntry
            containerStyle={styles.inputContainer}
          />

          <ErrorMessage error={registerError} visible={registerError} />

          <SubmitButton title="Register" buttonStyle={styles.loginButton} />
        </View>
        <View style={styles.signUpWrapper}>
          <Text style={styles.signUpTextSimple}>Already have an account?</Text>
          <TouchableOpacity onPress={() => navigation.navigate(routes.LOGIN)}>
            <Text style={styles.signUpText}> Log in</Text>
          </TouchableOpacity>
        </View>
      </AppForm>
    </AuthWrapper>
  );
};

const styles = StyleSheet.create({
  loginButton: {
    backgroundColor: colors.mainColor,
    borderRadius: 8,
    paddingVertical: 13,
    marginTop: 30,
  },
  label: {
    color: colors.mainColor,
    fontWeight: "700",
    fontSize: 12,
    marginBottom: 8,
  },
  inputContainer: {
    backgroundColor: colors.white,
    color: colors.white,
    borderRadius: 10,
    flexDirection: "row",
    padding: 10,
    borderWidth: 1,
    borderColor: colors.disabledGrey,
  },
  signUpWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 16,
  },
  signUpTextSimple: {
    fontSize: 10,
  },
  signUpText: {
    fontSize: 10,
    fontWeight: "700",
    color: colors.mainColor,
  },
});

export default SignupScreen;
