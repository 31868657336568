import { useCallback, useContext, useEffect, useState } from "react";
import { Text, View, Image, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { AntDesign, Feather, FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";

import theme from "../../styles/theme";
import Accordion from "../../components/Accordion";
import PersonalSVG from "../../svg/PersonalSVG";
import PersonalVisaSVG from "../../svg/PersonalVisaSVG";
import NotificationSVG from "../../svg/NotificationSVG";
import NewNotificationSVG from "../../svg/NewNotification";
import NotesSVG from "../../svg/NotesSVG";
import routes from "../../navigation/routes";
import AppContext from "../../context/context";
import UserInformation from "../../components/UserInformation";
import PersonalVisa from "../../components/PersonalVisa";
import useApi from "../../hooks/useApi";
import notesAPI from "../../api/notes";
import notificationsAPI from "../../api/notification";
import usersAPI from "../../api/user";
import useAuth from "../../hooks/useAuth";
import Submit from "../../utils/handleSubmit";
import BusinessSVG from "../../svg/BusinessSVG";
import BusinessInformation from "../../components/BusinessInformation";
import ContractInformation from "../../components/ContractInformation";
import AddressInformation from "../../components/AddressInformation";

const { colors } = theme;
const flexDirectionStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
};
const HomeScreen = () => {
  const { user } = useContext(AppContext);
  const auth = useAuth();

  const getNotesApi = useApi(notesAPI.getNotes);
  const getNotificationsApi = useApi(notificationsAPI.getNotifications);
  const deleteUsersApi = useApi(usersAPI.deleteUser);

  const navigation = useNavigation();
  const userApi = useApi(usersAPI.updateUser);
  const [isFocused, setIsFocused] = useState(false);
  const [token, setToken] = useState(false);
  const [notes, setNotes] = useState(null);
  const [notifications, setNotifications] = useState(null);
  let intervalId = null;

  const checkToken = () => {
    if (!user?.id) return;
    if (token) return;

    const playerId = window?.playerId;
    console.log(playerId, "sended playerId");
    setToken(playerId);

    if (playerId) {
      clearInterval(intervalId);
      const OneSignal = window?.OneSignal;
      OneSignal?.push(function () {
        OneSignal.registerForPushNotifications();
      });
      userApi.request({
        id: user?.id,
        values: {
          crmInfo: user,
          playerId,
        },
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      setIsFocused(true);
      return () => {
        setIsFocused(false);
      };
    }, [])
  );

  useEffect(() => {
    if (!isFocused) return;
    intervalId = setInterval(checkToken, 1000);
    getNotes();
    getNotifications();
  }, [isFocused]);

  const logOut = () => auth.logout();

  const getNotes = useCallback(async () => {
    const response = await getNotesApi.request({ id: user?.id });
    if (!response.ok) return;
    const data = response.data?.sort((a, b) => {
      return new Date(b.Created_Time) - new Date(a.Created_Time);
    });
    setNotes(data);
  }, []);

  const getNotifications = useCallback(async () => {
    const response = await getNotificationsApi.request({ userId: user?.id });
    if (!response.ok) return;
    const data = response.data?.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    setNotifications(data);
  }, []);

  const handleDeleteAccount = async (id) => {
    const result = await Submit(deleteUsersApi, { id });
    if (result) {
      await logOut();
    }
  };

  return (
    <ScrollView>
      <View style={styles.wrapper}>
        <Image source={require("../../../assets/user.jpg")} style={styles.imgWrapper} />
        <TouchableOpacity
          style={styles.notificationWrapper}
          onPress={() =>
            navigation.navigate(routes.NOTIFICATION, { notificationData: notifications })
          }
        >
          {notifications?.some((item) => item.readed === false || item.readed === null) ? (
            <NewNotificationSVG />
          ) : (
            <NotificationSVG />
          )}
        </TouchableOpacity>
      </View>
      <View style={styles.textWrapper}>
        <View style={styles.topCenter}>
          <Text style={styles.name}>{user?.Full_Name}</Text>
        </View>
        <View style={styles.topCenter}>
          <Text style={styles.business}>{user?.Status}</Text>
        </View>
      </View>

      <Accordion
        title={
          <View style={styles.collapsibleTitleWrapper}>
            <PersonalSVG />
            <Text style={styles.collapsibleText}>Personal</Text>
          </View>
        }
      >
        <UserInformation />
      </Accordion>
      <Accordion
        title={
          <View style={styles.collapsibleTitleWrapper}>
            <PersonalVisaSVG />
            <Text style={styles.collapsibleText}>Visa</Text>
          </View>
        }
      >
        <PersonalVisa />
      </Accordion>
      <Accordion
        title={
          <View style={styles.collapsibleTitleWrapper}>
            <BusinessSVG />
            <Text style={styles.collapsibleText}>Business</Text>
          </View>
        }
      >
        <BusinessInformation />
      </Accordion>
      <Accordion
        title={
          <View style={styles.collapsibleTitleWrapper}>
            <AntDesign name="book" size={24} color="#154086" />
            <Text style={styles.collapsibleText}>Contract</Text>
          </View>
        }
      >
        <ContractInformation />
      </Accordion>
      <Accordion
        title={
          <View style={styles.collapsibleTitleWrapper}>
            <FontAwesome5 name="address-card" size={24} color="#154086" />
            <Text style={styles.collapsibleText}>Address</Text>
          </View>
        }
      >
        <AddressInformation />
      </Accordion>
      <TouchableOpacity
        style={styles.notesWrapper}
        onPress={() => navigation.navigate(routes.NOTES, { notes, userId: user?.id })}
      >
        <View style={styles.header}>
          <View style={styles.headerLeftItems}>
            <NotesSVG />
            <Text style={styles.collapsibleText}>Note's</Text>
          </View>
          <View style={styles.headerRightItems}>
            <View style={styles.noteCountWrapper}>
              <Text style={styles.noteCounterNumber}>{notes?.length}</Text>
            </View>
            <Feather name="arrow-up-right" size={15} color={colors.mainColor} />
          </View>
        </View>
      </TouchableOpacity>
      {user?.Files_URL ? (
        <TouchableOpacity
          style={styles.notesWrapper}
          onPress={() => WebBrowser.openBrowserAsync(user.Files_URL).then()}
        >
          <View style={styles.header}>
            <View style={styles.headerLeftItems}>
              <AntDesign name="link" size={15} color={colors.mainColor} />
              <Text style={styles.collapsibleText}>Files</Text>
            </View>
            <View style={styles.headerRightItems}>
              <Feather name="arrow-up-right" size={15} color={colors.mainColor} />
            </View>
          </View>
        </TouchableOpacity>
      ) : null}
      <View style={styles.settingsWrapper}>
        <View style={styles.settingInfo}>
          {/* <View style={styles.itemWrapper}>
            <MaterialIcons name="delete-outline" size={17} style={styles.iconStyleLogout} />
            <Text
              style={styles.logoutText}
              onPress={() =>
                deleteAlert(
                  "Delete account",
                  "Are you sure you want to delete your account?",
                  null,
                  () => handleDeleteAccount(user?.id)
                )
              }
            >
              Delete account
            </Text>
          </View> */}
          <View style={styles.itemWrapper}>
            <MaterialIcons name="logout" size={17} style={styles.iconStyleLogout} />
            <Text style={styles.logoutText} onPress={logOut}>
              Logout
            </Text>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 10,
  },
  imgWrapper: {
    marginTop: 10,
    width: 120,
    height: 120,
    borderRadius: 200,
  },
  textWrapper: {
    justifyContent: "center",
    alignItems: "center",
  },
  topCenter: {
    justifyContent: "flex-start",
    alignItems: "center",
  },
  name: { fontWeight: "700", fontSize: 16, color: colors.mainColor, marginBottom: 2 },
  business: { fontSize: 12, color: colors.secondaryColor, marginBottom: 40 },
  collapsibleTitleWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
  },
  collapsibleText: {
    marginTop: 5,
    fontWeight: "500",
    fontSize: 15,
    color: colors.mainColor,
    marginLeft: 10,
  },
  notesWrapper: {
    backgroundColor: colors.lightGrey,
    marginHorizontal: 16,
    marginVertical: 4,
    borderRadius: 10,
  },

  header: {
    ...flexDirectionStyle,
    alignItems: "center",

    height: 40,
  },
  headerLeftItems: {
    ...flexDirectionStyle,
    marginLeft: 10,
  },
  headerRightItems: {
    ...flexDirectionStyle,
    marginRight: 15,
  },
  noteCountWrapper: {
    borderRadius: 18,
    width: 18,
    height: 18,
    backgroundColor: colors.secondaryColor,
    marginRight: 15,
  },
  noteCounterNumber: {
    textAlign: "center",
    fontWeight: "700",
    color: colors.white,
  },
  notificationWrapper: {
    position: "absolute",
    right: 16,
    top: 10,
  },
  settingsWrapper: {
    marginHorizontal: 18,
    marginTop: 30,
  },
  settingInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemWrapper: {
    flexDirection: "row",
  },
  logoutText: {
    color: colors.mainColor,
    fontSize: 13,
    fontWeight: "700",
  },
  iconStyleLogout: {
    color: colors.mainColor,
    textAlign: "center",
    marginRight: 5,
    marginLeft: 2,
  },
});

export default HomeScreen;
