import { DefaultTheme } from "@react-navigation/native";
import { createTheme } from "@rneui/themed";
import { rgb } from "color";
import { Appearance } from "react-native";

const isDark = Appearance.getColorScheme().toString() === "dark";
const theme = createTheme({
  mode: isDark ? "dark" : "light",
});
const { colors } = DefaultTheme; //isDark ? DarkTheme : DefaultTheme;
const otherColors = {
  transparent: rgb(255, 255, 255, 0),
  inactiveTabBarColor: "#BAAA70",
  mainColor: "#154086",
  secondaryColor: "#BAAA70",
  grey: "#ACACAC",
  grey2: "#D9D9D9",
  disabledGrey: "#D8D8D8",
  lightGrey: "#F6F6F7",
  lightBlack: "#3C3C3C",
  yellow: "#FFD06A",
  // disabledGreen: "#C3FEBA",
  // darkGreen: "#01605A",
  // darkGreenSecondary: "#003A37",
  // lightGrey: "#D6D6D6",
  // lightGreySecondary: "#EAEAEA",
  // lightSilver: "#D9D9D9",
  // disabledTextColor: isDark ? "#c1c1c1" : "#414141",
  // textColor: isDark ? "#c1c1c1" : "#575756",
  // darkTextColor: "#575756",
  // purpleGrey: "#BDBCDB",
  // disabledButtonColor: "#F4F3FE",
  // inputText: colors.black,
  // error: "#FF0000",
};
export default {
  isDark,
  colors: isDark
    ? // ? { ...theme.darkColors, ...colors, ...otherColors }
      { ...theme.lightColors, ...colors, ...otherColors }
    : { ...theme.lightColors, ...colors, ...otherColors },
};
