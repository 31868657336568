import { useState, useCallback, useEffect } from "react";
import { ScrollView, View, StyleSheet } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import theme from "../../styles/theme";
import AppTextInput from "../../components/AppTextInput";
import SearchItem from "../../components/SearchItem";
import useApi from "../../hooks/useApi";
import videoAPI from "../../api/video";
import NoData from "../../components/NoData";

const { colors } = theme;

const Search = ({ navigation }) => {
  const getVideoApi = useApi(videoAPI.fetchVideos);
  const [isFocused, setIsFocused] = useState(false);
  const [videosData, setVideosData] = useState(null);
  const [searchString, setSearchString] = useState("");

  useFocusEffect(
    useCallback(() => {
      setIsFocused(true);
      setSearchString("");
      return () => {
        setIsFocused(false);
      };
    }, [])
  );

  useEffect(() => {
    getVideos();
  }, [isFocused, searchString]);

  const getVideos = useCallback(async () => {
    const response = await getVideoApi.request({ _q: searchString });
    response.ok && setVideosData(response.data);
  }, [searchString]);

  return (
    <ScrollView>
      <View style={styles.searchWrapper}>
        <AppTextInput
          iconPosition="right"
          placeholder="Search courses & webinars"
          icon="search1"
          iconType="AntDesign"
          placeholderTextColor={colors.grey}
          iconColor={colors.grey}
          defaultValue={searchString}
          onChangeText={(e) => setSearchString(e)}
        />
      </View>
      {!!videosData?.length ? (
        // <ScrollView
        //   showsVerticalScrollIndicator={false}
        //   contentContainerStyle={{ paddingBottom: 100 }}
        // >
        <>
          {videosData?.map((el) => (
            <View key={el.id}>
              <SearchItem navigation={navigation} data={el} />
            </View>
          ))}
        </>
      ) : (
        // </ScrollView>
        !getVideoApi?.loading && <NoData />
      )}
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  searchWrapper: { marginBottom: 8 },
});
export default Search;
