import client from "./client";
import { authAPI, profileAPI, registerAPI } from "./endpoints";

const login = (params) => client.post(authAPI, { ...params });

const profile = (params) => client.get(profileAPI, { ...params });

const register = (params) => client.post(registerAPI, { ...params });

export default { login, profile, register };
