import { memo } from "react";
import { Text, View, StyleSheet } from "react-native";
import theme from "../styles/theme";

const { colors } = theme;
const formattedDate = (isoDate) => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const NotesItem = ({ data }) => {
  const sentByCustomer = data?.Created_By?.email === "zoho@selectvisa.com";
  return (
    <View
      style={[
        styles.mainWrapper,
        {
          backgroundColor: sentByCustomer ? colors.secondaryColor : colors.mainColor,
          marginLeft: sentByCustomer ? 20 : 0,
          marginRight: sentByCustomer ? 0 : 20,
        },
      ]}
    >
      <Text
        style={[
          styles.title,
          {
            textAlign: sentByCustomer ? "right" : "left",
          },
        ]}
      >
        {sentByCustomer ? "You" : "Select Visa"}
      </Text>
      {/* {data?.message?.map((el) => ( */}
      <View style={styles.msgWrapper}>
        <Text style={styles.msgDate}>{formattedDate(data?.Created_Time)}</Text>
        <Text style={styles.msgText}>{data?.Note_Content}</Text>
      </View>
      {/* ))} */}
    </View>
  );
};
const styles = StyleSheet.create({
  mainWrapper: {
    paddingTop: 15,
    paddingHorizontal: 8,
    paddingBottom: 8,
    borderRadius: 10,
    marginBottom: 16,
  },
  title: {
    color: colors.white,
    fontSize: 15,
    fontWeight: "500",
    paddingBottom: 8,
  },
  msgWrapper: {
    justifyContent: "space-between",
    backgroundColor: colors.white,
    paddingHorizontal: 8,
    paddingVertical: 12,
    borderRadius: 7,
    marginTop: 8,
  },
  msgDate: {
    color: colors.lightBlack,
    fontSize: 12,
    fontWeight: "500",
    marginRight: 9,
  },
  msgText: {
    flex: 1,
    color: colors.mainColor,
    fontSize: 12,
    fontWeight: "500",
  },
});
export default memo(NotesItem);
