import { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import Collapsible from "react-native-collapsible";
import theme from "../styles/theme";

const { colors } = theme;

const Accordion = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <>
      <TouchableOpacity onPress={toggleCollapsed}>
        <View style={styles.header}>
          <View style={styles.title}>{title}</View>
          <MaterialIcons
            name={isCollapsed ? "keyboard-arrow-down" : "keyboard-arrow-up"}
            size={24}
            color={colors.mainColor}
          />
        </View>
      </TouchableOpacity>
      <Collapsible collapsed={isCollapsed}>
        <View style={styles.content}>{children}</View>
      </Collapsible>
    </>
  );
};

const styles = {
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.lightGrey,
    padding: 10,
    marginHorizontal: 16,
    marginVertical: 4,
    borderRadius: 10,
  },
  title: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
  },
  content: {
    padding: 20,
    backgroundColor: "#fff",
    marginHorizontal: 16,
    // marginVertical: 4,
  },
};

export default Accordion;
