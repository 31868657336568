import { Text, View, StyleSheet, ScrollView } from "react-native";
import theme from "../../styles/theme";
import Search from "./Search";

const { colors } = theme;
const MainSearch = ({ navigation }) => {
  return (
    <ScrollView>
      <View style={styles.mainWrapper}>
        <Text style={styles.text}>Search</Text>
        <Search navigation={navigation} />
      </View>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  mainWrapper: {
    margin: 16,
  },
  text: {
    fontSize: 20,
    fontWeight: "700",
    color: colors.mainColor,
    marginBottom: 14,
  },
});
export default MainSearch;
