import { useContext } from "react";
import { View, Text, StyleSheet } from "react-native";
import AppContext from "../context/context";

const BusinessInformation = () => {
  const { user } = useContext(AppContext);

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.label}>Company Name:</Text>
        <Text style={styles.value}>{user?.Company_Name}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Business Category:</Text>
        <Text style={styles.value}>{user?.Business_Category}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Business Establish Date:</Text>
        <Text style={styles.value}>{user?.Business_Start_Date}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Start of First Year:</Text>
        <Text style={styles.value}>{user?.Start_of_First_Year}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Accountant:</Text>
        <Text style={styles.value}>{user?.Accountant}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Company ABN:</Text>
        <Text style={styles.value}>{user?.Company_ABN}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 5,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 0,
    width: 140,
  },
  value: {
    marginBottom: 5,
  },
});

export default BusinessInformation;
