import { useContext } from "react";
import { View, Text, StyleSheet } from "react-native";
import AppContext from "../context/context";

const UserInformation = () => {
  const { user } = useContext(AppContext);

  // const { Full_Name, Phone, Mobile, Email } = user; TO DO
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.label}>Name:</Text>
        <Text style={styles.value}>{user?.Full_Name}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Email:</Text>
        <Text style={styles.value}>{user?.Email}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Mobile:</Text>
        <Text style={styles.value}>{user?.Mobile}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Phone:</Text>
        <Text style={styles.value}>{user?.Phone}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Date of Birth:</Text>
        <Text style={styles.value}>{user?.Date_of_Birth}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 5,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 0,
    width: 100,
  },
  value: {
    marginBottom: 5,
  },
});

export default UserInformation;
