import { useContext } from "react";
import { View, Text, StyleSheet } from "react-native";
import AppContext from "../context/context";

const AddressInformation = () => {
  const { user } = useContext(AppContext);
  const generateFullAddress = (street, city, province, zip, country) => {
    const addressComponents = [street, city, province, zip, country];
    const nonEmptyComponents = addressComponents.filter(
      (component) => component && component?.trim() !== ""
    );

    return nonEmptyComponents.join(", ");
  };

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.label}>Business Address (Premises):</Text>
        <Text style={styles.value}>{user?.Business_address}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Business Address (Registered address):</Text>
        <Text style={styles.value}>{user?.Business_address_Registered_Office}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Residential Address:</Text>
        <Text style={styles.value}>
          {generateFullAddress(
            user?.Mailing_Street,
            user?.Mailing_City,
            user?.Mailing_State,
            user?.Mailing_Zip,
            user?.Mailing_Country
          )}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 5,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 0,
    width: 140,
  },
  value: {
    marginBottom: 5,
  },
});

export default AddressInformation;
