import Constants from "expo-constants";
import { StyleSheet, SafeAreaView, Platform } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const AppScreen = ({ children, style, keyboardAware }) => {
  return (
    <SafeAreaView style={[styles.screen, style]}>
      {keyboardAware ? (
        <KeyboardAwareScrollView
          contentContainerStyle={styles.scrollViewWrapper}
          enableAutomaticScroll={Platform.OS === "ios"}
        >
          {children}
        </KeyboardAwareScrollView>
      ) : (
        <>{children}</>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  screen: {
    paddingTop: Constants.statusBarHeight + 20,
    flex: 1,
    width: "100%",
    backgroundColor: "white",
  },
  scrollViewWrapper: {
    flex: 1,
  },
});

export default AppScreen;
