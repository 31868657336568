import { memo } from "react";
import { Text, View, StyleSheet } from "react-native";
import moment from "moment";

import NotificationVideoSVG from "../svg/NotificationVideoSVG";
import NotificationNoteSVG from "../svg/NotificationNoteSVG";
import theme from "../styles/theme";

const { colors } = theme;

const NotificationItem = ({ type, isRead, date, message }) => {
  return (
    <View
      style={[
        styles.mainWrapper,
        { backgroundColor: isRead ? colors.lightGrey : colors.mainColor },
      ]}
    >
      <View style={styles.subWrapper}>
        <View style={styles.iconWrapper}>
          {type === "video" ? <NotificationVideoSVG /> : <NotificationNoteSVG />}
        </View>
        <View style={styles.textWrapper}>
          {type === "video" ? (
            <Text style={[styles.title, { color: isRead ? colors.mainColor : colors.white }]}>
              Video's
            </Text>
          ) : (
            <Text style={[styles.title, { color: isRead ? colors.mainColor : colors.white }]}>
              Notes's
            </Text>
          )}
          <Text style={[styles.information, { color: isRead ? colors.mainColor : colors.white }]}>
            {message}
          </Text>
          <Text style={styles.date}>
            {moment(date).format("DD MMM YYYY")} - {moment(date).format("HH:mm")}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainWrapper: {
    marginBottom: 8,
    borderRadius: 10,
    padding: 8,
  },
  subWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconWrapper: {
    backgroundColor: colors.white,
    padding: 13,
    borderRadius: 7,
  },
  textWrapper: {
    flex: 2,
    marginLeft: 13,
  },
  title: {
    fontSize: 15,
    fontWeight: "700",
    marginBottom: 2,
  },
  information: {
    fontSize: 10,
    fontWeight: "400",
    marginBottom: 6,
  },
  informationBusiness: {
    color: colors.secondaryColor,
    marginBottom: 6,
  },
  date: {
    fontSize: 8,
    fontWeight: "400",
    color: colors.grey,
  },
});

export default memo(NotificationItem);
