import { useContext } from "react";
import { TouchableOpacity, Alert } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";

import routes from "./routes";
import HomeScreen from "../Screens/HomeScreen";
import NotesScreen from "../Screens/NotesScreen";
import NotificationsScreen from "../Screens/NotificationsScreen";
import useApi from "../hooks/useApi";
import notificationsAPI from "../api/notification";
import Submit from "../utils/handleSubmit";
import AppContext from "../context/context";
import MarkAsReadSVG from "../svg/MarkAsReadSVG";

import theme from "../styles/theme";

const { colors } = theme;

const Stack = createStackNavigator();

export default () => {
  const postNotificationsApi = useApi(notificationsAPI.postNotification);
  const { user } = useContext(AppContext);
  return (
    <Stack.Navigator>
      <Stack.Screen
        name={routes.MAINHOME}
        component={HomeScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={routes.NOTES}
        component={NotesScreen}
        options={({ navigation }) => ({
          headerTintColor: colors.mainColor,
          headerBackTitleVisible: false,
        })}
      />
      <Stack.Screen
        name={routes.NOTIFICATION}
        component={NotificationsScreen}
        options={{
          headerTintColor: colors.mainColor,
          headerBackTitleVisible: false,
          tabBarVisible: false,
          headerRight: () => (
            <TouchableOpacity
              style={{ marginRight: 20 }}
              onPress={() =>
                Submit(
                  postNotificationsApi,
                  {
                    id: user?.id,
                    readAll: true,
                  },
                  () => {
                    Alert.alert("Error", "Please try again later.");
                  },
                  () => {}
                )
              }
            >
              <MarkAsReadSVG />
            </TouchableOpacity>
          ),
        }}
      />
    </Stack.Navigator>
  );
};
