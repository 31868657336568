import { create } from "apisauce";

import storage from "../auth/storage";
import settings from "../config/settings";
import cache from "../utils/cache";

export const baseURL = settings.apiUrl;

const client = create({ baseURL });

client.addAsyncRequestTransform(async (request) => {
  const token = await storage.getToken();
  if (!token || request?.url.includes("crm/")) return;
  request.headers.Authorization = `Bearer ${token}`;
});

const getMethod = client.get;

client.get = async (url, params, axiosConfig) => {
  const response = await getMethod(url, params, axiosConfig);
  const token = await storage.getToken();

  if (response.ok) {
    token && cache.store(url, response.data);
    return response;
  }
  if (!token) return response;
  const data = await cache.get(url);

  return data ? { ok: false, data } : response;
};

export default client;
