import { isFunction } from "formik";
import { useState } from "react";
import { View, TextInput, TouchableOpacity } from "react-native";

import AppText from "./AppText";
import { renderIcon } from "../helpers/renderIcon";
import theme from "../styles/theme";
import SendSVG from "../svg/SendSVG";

const AppTextInput = ({
  onIconPress,
  icon,
  iconType,
  iconColor,
  iconSize,
  placeholder,
  onChangeText,
  title,
  onFocus,
  onBlur,
  containerStyle,
  iconPosition = "left",
  iconStyle,
  isSmallTitle = "true",
  placeholderTextColor,
  rightComponentStyle,
  handleIconPress,
  isNote = false,
  ...otherProps
}) => {
  const [text, setText] = useState(otherProps?.value || otherProps?.defaultValue || "");
  const [focused, setFocused] = useState(false);
  const leftMargin = iconPosition === "left" ? 40 : 10;

  return (
    <View
      style={
        focused
          ? [containerStyle || theme.styles.inputContainer, theme.styles.inputContainerFocused]
          : [containerStyle || theme.styles.inputContainer]
      }
    >
      {/* {text && isSmallTitle && (
        <AppText
          style={
            icon
              ? [theme.styles.inputLabel, { left: leftMargin }]
              : [theme.styles.inputLabel, { left: 10 }]
          }
        >
          {title || placeholder}
        </AppText>
      )} */}
      {iconPosition === "left" && (
        <AppText style={iconStyle}>{renderIcon(icon, iconType, iconColor, iconSize)}</AppText>
      )}
      <TextInput
        style={theme.styles.textInput}
        {...otherProps}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        onBlur={() => {
          setFocused(false);
          if (isFunction(onBlur)) onBlur();
        }}
        onFocus={() => {
          setFocused(true);
          if (isFunction(onFocus)) onFocus();
        }}
        onChangeText={(text) => {
          setText(text);
          if (isFunction(onChangeText)) onChangeText(text);
        }}
      />
      {isNote && (
        <TouchableOpacity style={rightComponentStyle} onPress={handleIconPress}>
          <SendSVG />
        </TouchableOpacity>
      )}
      {iconPosition === "right" && (
        <AppText style={{ marginLeft: -40, marginTop: 6 }} onPress={onIconPress}>
          {renderIcon(icon, iconType, iconColor, iconSize)}
        </AppText>
      )}
    </View>
  );
};

export default AppTextInput;
