import * as React from "react";
import Svg, { Path } from "react-native-svg";

const CategorySVG = (props) => (
  <Svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M10 8.0625V10.6875C10 12.875 9.125 13.75 6.9375 13.75H4.3125C2.125 13.75 1.25 12.875 1.25 10.6875V8.0625C1.25 5.875 2.125 5 4.3125 5H6.9375C9.125 5 10 5.875 10 8.0625Z"
      fill="#BAAA70"
    />
    <Path
      d="M10.6874 1.25H8.06236C6.13541 1.25 5.2317 1.93381 5.04343 3.58688C5.00404 3.93272 5.29046 4.21875 5.63853 4.21875H6.93736C9.56236 4.21875 10.7811 5.4375 10.7811 8.0625V9.36131C10.7811 9.70938 11.0671 9.99581 11.413 9.95644C13.066 9.76812 13.7499 8.86444 13.7499 6.9375V4.3125C13.7499 2.125 12.8749 1.25 10.6874 1.25Z"
      fill="#BAAA70"
    />
  </Svg>
);

export default CategorySVG;
