import { Formik } from "formik";
import { StyleSheet, View } from "react-native";

const AppForm = ({ initialValues, onSubmit, validationSchema, children }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {() => <View style={styles.container}>{children}</View>}
    </Formik>
  );
};

const styles = StyleSheet.create({
  // container: { flex: 1 },
});

export default AppForm;
