import { createStackNavigator } from "@react-navigation/stack";

import routes from "./routes";
import MainVideo from "../Screens/VideoScreen";
import SearchInfo from "../components/SearchInfo";
import theme from "../styles/theme";

const { colors } = theme;

const Stack = createStackNavigator();
export default () => (
  <Stack.Navigator>
    <Stack.Screen
      name={routes.MAIN_VIDEO}
      component={MainVideo}
      options={{
        headerShown: false,
      }}
    />
    <Stack.Screen
      name={routes.SEARCHITEM}
      component={SearchInfo}
      options={{
        headerTintColor: colors.mainColor,
        headerBackTitleVisible: false,
      }}
    />
  </Stack.Navigator>
);
