import { createStackNavigator } from "@react-navigation/stack";
import { useContext } from "react";

import routes from "./routes";
import LoginScreen from "../Screens/Auth/LoginScreen";
import SignupScreen from "../Screens/Auth/SignupScreen";

const Stack = createStackNavigator();

const AuthNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerTitleAlign: "center" }}>
      <Stack.Screen
        name={routes.LOGIN}
        component={LoginScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={routes.REGISTER}
        component={SignupScreen}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default AuthNavigator;
