import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SearchSVG = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M13.5 20C18.7366 20 23 15.7366 23 10.5C23 5.26341 18.7366 1 13.5 1C8.26341 1 4 5.26341 4 10.5C4 15.7366 8.26341 20 13.5 20ZM13.5 2.39024C17.9766 2.39024 21.6098 6.03268 21.6098 10.5C21.6098 14.9673 17.9766 18.6098 13.5 18.6098C9.02341 18.6098 5.39024 14.9673 5.39024 10.5C5.39024 6.03268 9.02341 2.39024 13.5 2.39024Z"
      fill={props?.color ? props.color : "#BAAA70"}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.76572 21.2938C3.77137 21.2885 3.77696 21.283 3.7825 21.2775L5.7825 19.2775C6.0725 18.9875 6.0725 18.5075 5.7825 18.2175C5.4925 17.9275 5.0125 17.9275 4.7225 18.2175L3.48585 19.4541C3.48037 19.4593 3.47495 19.4646 3.4696 19.4699L1.4696 21.4699C1.1796 21.7599 1.1796 22.2399 1.4696 22.5299C1.6196 22.6799 1.8096 22.7499 1.9996 22.7499C2.1896 22.7499 2.3796 22.6799 2.5296 22.5299L3.76572 21.2938Z"
      fill={props?.color ? props.color : "#BAAA70"}
    />
  </Svg>
);

export default SearchSVG;
