import { useContext } from "react";
import { View, Text, StyleSheet } from "react-native";
import AppContext from "../context/context";

const PersonalVisa = () => {
  const { user } = useContext(AppContext);

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.label}>Sponsored State:</Text>
        <Text style={styles.value}>{user?.Sponsored_State}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Visa Grant Date:</Text>
        <Text style={styles.value}>{user?.Visa_Grant_Date}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Visa Expiry Date:</Text>
        <Text style={styles.value}>{user?.Visa_Expiry_Date}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 5,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 0,
    width: 130,
  },
  value: {
    marginBottom: 5,
  },
});

export default PersonalVisa;
