import { useCallback, useEffect, useState } from "react";
import { ScrollView, View, StyleSheet, TouchableOpacity, Text, FlatList } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import theme from "../../styles/theme";
import AppTextInput from "../../components/AppTextInput";
import routes from "../../navigation/routes";
import useApi from "../../hooks/useApi";
import videoAPI from "../../api/video";
import SearchItem from "../../components/SearchItem";
import NoData from "../../components/NoData";

const { colors } = theme;

const Videos = ({ navigation }) => {
  const getVideoApi = useApi(videoAPI.fetchVideos);
  const [isFocused, setIsFocused] = useState(false);
  const [videosData, setVideosData] = useState(null);
  const [featuredVideo, setFeaturedVideo] = useState(null);

  useFocusEffect(
    useCallback(() => {
      setIsFocused(true);
      return () => {
        setIsFocused(false);
      };
    }, [])
  );

  useEffect(() => {
    getVideos();
  }, [isFocused]);

  const getVideos = useCallback(async () => {
    const response = await getVideoApi.request({});
    if (response.ok) {
      setVideosData(response.data);
      const filtered = response.data.filter((el) => el.featured);
      setFeaturedVideo(filtered);
    }
  }, []);

  return (
    <>
      <TouchableOpacity
        style={styles.searchWrapper}
        onPress={() => navigation.navigate(routes.SEARCH)}
      >
        <AppTextInput
          iconPosition="right"
          placeholder="Search courses & webinars"
          icon="search1"
          iconType="AntDesign"
          placeholderTextColor={colors.grey}
          iconColor={colors.grey}
          editable={false}
        />
      </TouchableOpacity>
      {!getVideoApi.error ? (
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 100 }}
        >
          <Text style={styles.title}>Registered Courses</Text>
          <View style={styles.registeredWrapper}>
            {videosData?.map((el) => (
              <SearchItem navigation={navigation} data={el} key={el?.id} />
            ))}
          </View>
          <Text style={styles.title}>Featured videos</Text>

          <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            data={featuredVideo}
            renderItem={({ item }) => (
              <View style={styles.wrapper}>
                <SearchItem navigation={navigation} data={item} />
              </View>
            )}
            keyExtractor={(item) => item.id}
          />
        </ScrollView>
      ) : (
        <NoData />
      )}
    </>
  );
};
const styles = StyleSheet.create({
  searchWrapper: { marginBottom: 8 },
  wrapper: {
    backgroundColor: colors.lightGrey,
    width: 350,
    padding: 10,
    borderRadius: 10,
    marginRight: 16,
  },
  title: { fontSize: 15, color: colors.mainColor, fontWeight: "700", marginBottom: 11 },
  registeredWrapper: {
    backgroundColor: colors.lightGrey,
    paddingBottom: 8,
    borderRadius: 10,
    marginBottom: 17,
  },
});
export default Videos;
