import { useContext, useState, useEffect, useCallback } from "react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import moment from "moment/moment";
import { TouchableOpacity } from "react-native";
import DateTimePicker from "react-native-modal-datetime-picker";
import { useFocusEffect } from "@react-navigation/native";

import ErrorMessage from "./ErrorMessage";
import AppTextInput from "../AppTextInput";
import AppContext from "../../context/context";

const AppFormField = ({ name, type = "simple", ...otherProps }) => {
  const { contactsCategories } = useContext(AppContext);
  const [isVisible, setVisible] = useState(false);
  const [selectedTagList, setSelectedTagList] = useState([]);
  const [categoriesIdToName, setCategoriesIdsToName] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  useFocusEffect(
    useCallback(() => {
      setIsFocused(true);
      return () => {
        setIsFocused(false);
      };
    }, [])
  );

  useEffect(() => {
    transferIdToName();
  }, [isFocused, selectedTagList?.length]);

  const { setFieldTouched, handleChange, errors, touched, values, setFieldValue } =
    useFormikContext();

  const getSelectedTags = () => {
    if (contactsCategories) {
      const tagsFromBackendById = contactsCategories?.map((el) => el.id);
      const filteredTagsFromBackendById = get(values, name, null)
        ?.filter((el) => tagsFromBackendById?.includes(el.id))
        ?.map((item) => item.id);
      (filteredTagsFromBackendById?.length != 0 || selectedTagList.length != 0) &&
        setSelectedTagList([...filteredTagsFromBackendById]);
    }
  };

  useEffect(() => {
    type === "contactCategories" && getSelectedTags();
  }, []);

  const transferIdToName = () => {
    if (type !== "contactCategories") return;
    if (
      (selectedTagList?.length === 1 && selectedTagList[0] === 0) ||
      selectedTagList?.length === 0
    ) {
      setCategoriesIdsToName("Everyone");
    } else {
      const haveId = contactsCategories?.filter((el) => selectedTagList?.includes(el.id));
      const categoriesNames = haveId?.map((el) => el.name)?.join(", ");
      categoriesNames?.length
        ? setCategoriesIdsToName(categoriesNames)
        : setCategoriesIdsToName("Everyone");
    }
  };
  if (type === "simple") {
    return (
      <>
        <AppTextInput
          defaultValue={get(values, name, null)}
          onChangeText={handleChange(name)}
          onBlur={() => setFieldTouched(name)}
          autoCapitalize="none"
          {...otherProps}
        />
        <ErrorMessage error={errors[name]} visible={touched[name]} />
      </>
    );
  } else if (type === "date" || type === "time") {
    return (
      <>
        <TouchableOpacity
          onPress={() => {
            setVisible(true);
          }}
        >
          <AppTextInput
            defaultValue={get(values, name, null)}
            onChangeText={handleChange(name)}
            onBlur={() => setFieldTouched(name)}
            autoCapitalize="none"
            {...otherProps}
          />
        </TouchableOpacity>
        <DateTimePicker
          mode={type}
          isVisible={isVisible}
          onCancel={() => {
            setVisible(false);
          }}
          onConfirm={(date) => {
            setVisible(false);
            setFieldTouched(name, true);
            setFieldValue(name, moment(date).format(type === "time" ? "HH:mm" : "DD/MM/YYYY"));
          }}
        />
      </>
    );
  }
};

export default AppFormField;
