import { useContext } from "react";
import { View, Text, StyleSheet } from "react-native";
import AppContext from "../context/context";

const ContractInformation = () => {
  const { user } = useContext(AppContext);

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.label}>Contract Date:</Text>
        <Text style={styles.value}>{user?.Contract_Date}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>2nd Instalment:</Text>
        <Text style={styles.value}>{user?.nd_Instalment}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>2nd Due Date:</Text>
        <Text style={styles.value}>{user?.nd_Due_Date}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>3rd Instalment:</Text>
        <Text style={styles.value}>{user?.rd_Istalment}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>3rd Due Date:</Text>
        <Text style={styles.value}>{user?.rd_Due_Date}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>4th Instalment:</Text>
        <Text style={styles.value}>{user?.th_Instalment}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>4th Due Date:</Text>
        <Text style={styles.value}>{user?.th_Due_Date}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 5,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 0,
    width: 100,
  },
  value: {
    marginBottom: 5,
  },
});

export default ContractInformation;
