import { useLayoutEffect, useState } from "react";
import * as WebBrowser from "expo-web-browser";

import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Platform,
  Dimensions,
  ScrollView,
} from "react-native";
import { MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";
import { WebView } from "react-native-webview";

import CategorySVG from "../svg/CategorySVG";
import theme from "../styles/theme";
import settings from "../config/settings";

const { colors } = theme;
const { height } = Dimensions.get("window");

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}

const SearchInfo = ({ navigation, route }) => {
  const { searchItemInformation } = route.params;
  const { link, description, duration, category, title, image } = searchItemInformation;
  const [showWebView, setShowWebView] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({ title });
  }, [navigation]);

  const thumbnail = image?.formats?.small?.url
    ? `${settings.apiUrl}${image?.formats?.small?.url}`
    : "";

  return (
    <>
      <View style={{ flex: 1 }}>
        {showWebView ? (
          <>
            {Platform.OS === "web" ? (
              <View style={{ flex: 1 }}>
                <iframe src={link} style={{ width: "100%", height: height }} />
              </View>
            ) : (
              <WebView
                source={{
                  uri: link,
                }}
                onShouldStartLoadWithRequest={() => true}
                style={{ flex: 1 }}
              />
            )}
          </>
        ) : (
          <View style={styles.mainWrapper}>
            <TouchableOpacity
              style={styles.imageContainer}
              onPress={() => WebBrowser.openBrowserAsync(link).then()}
            >
              <Image
                source={thumbnail ? { uri: thumbnail } : require("../../assets/searchItemTest.jpg")}
                style={styles.videoStyle}
              />
              <FontAwesome5 name="play-circle" size={60} style={styles.playIcon} />
            </TouchableOpacity>
            <View style={styles.reviewWrapper}>
              <CategorySVG />
              <Text style={styles.review}>{category?.name}</Text>
              <MaterialCommunityIcons name="clock" size={14} style={styles.timeIcon} />
              <Text style={styles.timeText}>{fancyTimeFormat(duration)}</Text>
            </View>
            <View style={styles.descriptionWrapper}>
              <Text style={styles.descriptionTitle}>Description</Text>
              <Text style={styles.descriptionText}>{description}</Text>
            </View>
          </View>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  mainWrapper: {
    margin: 16,
  },
  videoStyle: {
    width: "100%",
    height: 220,
    borderRadius: 10,
  },
  reviewWrapper: {
    flexDirection: "row",
    marginVertical: 10,
  },
  review: {
    marginLeft: 4,
    marginTop: 1,
    color: colors.secondaryColor,
    fontSize: 10,
    fontWeight: "600",
  },
  timeIcon: {
    color: colors.grey,
    fontSize: 10,
    marginTop: 2,
    marginLeft: 18,
  },
  timeText: {
    color: colors.grey,
    fontSize: 10,
    fontWeight: "600",
    marginLeft: 3,
    marginTop: 1,
  },
  descriptionWrapper: {
    borderWidth: 1,
    borderColor: colors.grey2,
    borderRadius: 10,
    paddingHorizontal: 16,
    paddingVertical: 13,
  },
  descriptionTitle: {
    color: colors.grey,
    fontSize: 12,
    marginBottom: 10,
  },
  descriptionText: {
    color: colors.lightBlack,
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 21,
  },
  imageContainer: {
    position: "relative",
    width: "100%",
  },
  playIcon: {
    color: "#FFFFFF80",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: [{ translateX: -30 }, { translateY: -30 }],
  },
});
export default SearchInfo;
