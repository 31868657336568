import AppScreen from "components/AppScreen";
import { StyleSheet, View } from "react-native";

import AppActivityIndicator from "../../components/AppActivityIndicator";
import { Image } from "react-native";

const AuthWrapper = ({ children, loading, done }) => {
  return (
    <AppScreen keyboardAware={true}>
      <AppActivityIndicator visible={loading} height={260} />

      <View style={styles.loginScreen}>
        <View style={styles.logoWrapper}>
          <Image style={styles.logo} source={require("../../../assets/logo.png")} />
        </View>

        {children}
      </View>
    </AppScreen>
  );
};

const styles = StyleSheet.create({
  loginScreen: {
    flex: 1,
    paddingHorizontal: 34,
    justifyContent: "center",
  },
  logoWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    alignSelf: "center",
    marginBottom: 15,
  },
  logo: {
    marginTop: 10,
    width: 120,
    height: 120,
  },
});

export default AuthWrapper;
