import { createStackNavigator } from "@react-navigation/stack";

import routes from "./routes";
import MainSearch from "../Screens/SearchScreen";
import SearchInfo from "../components/SearchInfo";
import theme from "../styles/theme";

const { colors } = theme;

const Stack = createStackNavigator();
export default () => (
  <Stack.Navigator>
    <Stack.Screen
      name={routes.MAIN_SEARCH}
      component={MainSearch}
      options={{
        headerShown: false,
      }}
    />
    <Stack.Screen
      name={routes.SEARCHITEM}
      component={SearchInfo}
      options={{
        headerTintColor: colors.mainColor,
        headerBackTitleVisible: false,
      }}
    />
  </Stack.Navigator>
);
