import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StyleSheet, View } from "react-native";

import theme from "../../styles/theme";
import AppText from "../AppText";

const ErrorMessage = ({
  error,
  visible,
  icon = "information",
  iconSize = 20,
  iconColor = theme.colors.error,
  iconStyle,
}) => {
  if (!visible || !error) return null;
  return (
    <View style={theme.styles.errorContainer}>
      <MaterialCommunityIcons
        name={icon}
        size={iconSize}
        color={iconColor || theme.colors.inputText}
        style={iconStyle || theme.styles.textIcon}
      />
      <AppText style={styles.error}>{error}</AppText>
    </View>
  );
};

const styles = StyleSheet.create({
  error: {
    color: theme.colors.error,
    fontSize: 16,
    alignSelf: "center",
    paddingRight: 50,
  },
  icon: { paddingRight: 10, paddingLeft: 10, alignSelf: "center" },
});

export default ErrorMessage;
