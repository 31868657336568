import { useEffect, useState, useCallback } from "react";
import { View, StyleSheet, FlatList, Text } from "react-native";
import NotificationItem from "../../components/NotificationItem";
import useApi from "../../hooks/useApi";
import notificationsAPI from "../../api/notification";
import Submit from "../../utils/handleSubmit";

const NotificationsScreen = ({ route }) => {
  const { notificationData } = route.params;
  const updateNotificationsApi = useApi(notificationsAPI.updateNotification);

  const [visibleNotifications, setVisibleNotifications] = useState(null);
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    if (!notifications) return;
    updateNotifications(notifications?.map((el) => el?.item));
  }, [visibleNotifications]);

  const onViewableItemsChanged = useCallback((info) => {
    setVisibleNotifications(info?.changed?.length);
    setNotifications(info?.viewableItems);
  }, []);

  const updateNotifications = (notifications) => {
    const unreadItems = notifications.filter((item) => !item.readed);

    unreadItems.forEach((item) => {
      Submit(updateNotificationsApi, {
        id: item?.id,
        values: { readed: true, readedAt: Date.now() },
      });
    });
  };

  return (
    <View style={styles.mainWrapper}>
      <FlatList
        showsHorizontalScrollIndicator={false}
        data={notificationData}
        onViewableItemsChanged={onViewableItemsChanged}
        renderItem={({ item }) => (
          <NotificationItem
            type={item?.type}
            isRead={item?.readed}
            date={item?.created_at}
            key={item?.id}
            message={item?.message}
          />
        )}
        keyExtractor={(item) => item.id}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  mainWrapper: {
    marginHorizontal: 16,
    marginVertical: 0,
  },
});

export default NotificationsScreen;
