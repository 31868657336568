import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";

import theme from "../styles/theme";
import CategorySVG from "../svg/CategorySVG";
import routes from "../navigation/routes";
import settings from "../config/settings";

const { colors } = theme;

const SearchItem = ({ navigation, data }) => {
  const { title, description, image, category } = data;
  const thumbnail = image?.formats?.small?.url
    ? `${settings.apiUrl}${image?.formats?.small?.url}`
    : "";
  return (
    <TouchableOpacity
      style={styles.mainWrapper}
      onPress={() => navigation.navigate(routes.SEARCHITEM, { searchItemInformation: data })}
    >
      <Image
        source={thumbnail ? { uri: thumbnail } : require("../../assets/searchItemTest.jpg")}
        style={styles.imgStyles}
      />
      <View style={styles.textWrapper}>
        <Text style={styles.title}>{title}</Text>
        <Text numberOfLines={4} style={styles.description}>
          {description}
        </Text>
        <View style={styles.categoryWrapper}>
          <CategorySVG />
          <Text style={styles.category}>{category?.name}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors.lightGrey,
    borderRadius: 10,
    flexDirection: "row",
    marginTop: 8,
  },

  imgStyles: {
    width: 150,
    height: 88,
    margin: 8,
    borderRadius: 10,
  },
  textWrapper: {
    width: "50%",
    marginTop: 8,
  },
  title: {
    color: colors.mainColor,
    fontSize: 15,
    fontWeight: "700",
    marginBottom: 2,
  },
  description: {
    color: colors.grey,
    fontSize: 9,
    marginBottom: 7,
  },
  categoryWrapper: {
    flexDirection: "row",
  },
  category: {
    marginLeft: 4,
    marginTop: 1,
    color: colors.secondaryColor,
    fontSize: 10,
    fontWeight: "600",
  },
});
export default SearchItem;
